<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="mt-1"
             :selectedNow="selectedNow"
             :dataValue="item.id"
        />
        <div class="table-card__item-number"
            @click="$emit('showTransactionInfo')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
        <div class="table-card__item-head-txt" v-if="!userTabs.payoneer.active">
          <template v-if="item.payment_transaction_detail !== null &&
                item.payment_transaction_detail.payment_transaction_contact !== null">
            {{item.payment_transaction_detail.payment_transaction_contact.full_name}}
          </template>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('mypayments_transaction.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.transaction_id}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('mypayments_sum.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="userTabs.payoneer.active">
                {{item.amount}}
              </template>
              <template v-else>
                {{item.user_amount}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col" v-if="!userTabs.payoneer.active">
            <div class="table-card__item-label">
              {{$t('mypayments_sumToPay.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.payment_total}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-right__item">
            <LinkButton
                    :value="'Print Invoice'"
                    :type="'print'"
                    :datePicker="true"
                    @changeDate="date => $emit('changeDate', date)"
                    @datePickerBtn="$emit('printInvoice')"
                    :popup-class="'mobile-center'"
                    :datePickerRange="false"
                    :datePickerTxt="'Print Invoice'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        Show More
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "MyPaymentsTableMobile",

    components: {
      LinkButton,
      DefaultCheckbox,
    },

    props: ['item', 'userTabs', 'selectedNow', 'showTransactionInfo'],

    data() {
      return {
        show: false
      }
    },

  }
</script>

<style scoped>

</style>
