<template>
          <!--v-if="$store.getters.getCurrentUserRights.additionalPermissions && !$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"-->
  <div>
    <MyPaymentsTableUser
            @changeTab="(tab) => changeTab(tab, 'userTabs')"
            :userTabs="userTabs"
            @filter="filter"
            :filterGetParams="filterGetParams"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @resetFilter="resetFilter"
            :countFilterParams="countFilterParams"
            @downloadFiles="downloadFiles"
            @printInvoice="printInvoice"
    />

    <PrintPaymentInvoicePopup
        v-if="printInvoicePopup"
        :printData="printData"
        :userTabs="userTabs"
        @close="printInvoicePopup = false"
    />
  </div>
</template>

<script>
  import MyPaymentsTableUser from "./MyPaymentsTable/MyPaymentsTableUser/MyPaymentsTableUser";
  import {shopDetails} from "../../../../mixins/shopMixins/shopTypeFunc";
  import {routeFilter} from "../../../../mixins/routeFilterMixins/routeFilter";
  import PrintPaymentInvoicePopup
    from "@/components/modules/MyPaymentsModule/popups/PrintPaymentInvoicePopup/PrintPaymentInvoicePopup";

  export default {
    name: "MyPaymentsTable",
    components: {
      PrintPaymentInvoicePopup,
      MyPaymentsTableUser
    },

    mixins: [shopDetails, routeFilter],

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getMyPaymentsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterOrder: '',
        filterUserName: '',
        filterUserId: '',
        filterType: '',
        filterCustomer: '',
        filterTransactionNumber: '',
        filterSum: '',
        filterSumToPay: '',

        filterGetParams: {},
        countFilterParams: 0,
        typeShops: 0,

        userTabs:{
          paypal: {
            name: 'PayPal',
            value: 'paypal',
            id: 1,
            active: true,
            permissionVisible: false,
          },
          authorize: {
            name: 'Authorize',
            value: 'authorize',
            id: 2,
            active: false,
            permissionVisible: false,
          },
          braintree: {
            name: 'Braintree',
            value: 'braintree',
            id: 3,
            active: false,
            permissionVisible: false,
          },
          payoneer: {
            name: 'Payoneer',
            value: 'payoneer',
            id: 4,
            active: false,
            permissionVisible: false,
          },
        },

        printInvoicePopup: false,
        printData: null,
      }
    },

    created() {
      if(this.$route.query.type !== undefined){
        this.userTabs.paypal.active = false
        this.userTabs[this.$route.query.type].active = true
      }

      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    },

    methods: {

      checkTabPermission() {
        this.activeTabByPermissions('userTabs', {
          [this.userTabs.paypal.value]: this.PERMISSIONS.MY_PAYMENT_PAYPAL,
          [this.userTabs.authorize.value]: this.PERMISSIONS.MY_PAYMENT_AUTHORIZE,
          [this.userTabs.braintree.value]: this.PERMISSIONS.MY_PAYMENT_BRAINTREE,
          [this.userTabs.payoneer.value]: this.PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER,
        })

        if(!this._.find(this.userTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('userTabs')
          return
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          order: this.$route.query.order,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          type: this.$route.query.type,
          customer: this.$route.query.customer,
          transactionNumber: this.$route.query.transactionNumber,
          sum: this.$route.query.sum,
          sumToPay: this.$route.query.sumToPay,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      changeTab(id) {
        Object.keys(this.userTabs).map((item) => {
          this.userTabs[item].active = false
          if(item === id){
            this.userTabs[item].active = true
          }
        })
        this.changeFilter({type: id})
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextMyPaymentsPage', true)

        // save filter params in store
        this.$store.commit('setMyPaymentsFilter', window.location.search)

        if(this.userTabs.payoneer.active) {

          this.$store.dispatch('fetchMyPaymentsPayoneer', {filter: url}).then(() => {
            // skip get next page
            this.$store.commit('setNextMyPaymentsPage', false)
          })

        } else {

          let type = this.filterType !== '' ? this.filterType : 'paypal'

          this.$store.dispatch('fetchMyPayments', {type: type, filter: url}).then(() => {
            // skip get next page
            this.$store.commit('setNextMyPaymentsPage', false)
          })

        }

        this.checkCountFilter(['type', 'userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if (this.filterOrder !== '') myQuery.where('PaymentId', this.filterOrder)
        // if (this.filterType !== '') myQuery.where('PaymentTransactionId', this.filterTransactionNumber)
        if (this.filterCustomer !== '') myQuery.where('buyer_name', this.filterCustomer)
        if (this.filterTransactionNumber !== '') myQuery.where('PaymentTransactionId', this.filterTransactionNumber)
        if (this.filterSum !== '') myQuery.where('PaymentTransactionUserAmount', this.filterSum)
        if (this.filterSumToPay !== '') myQuery.where(this.userTabs.paypal.active ? 'PaymentTransactionPaymentTotal' : 'PaymentTransactionPaymentTotal', this.filterSumToPay)

        if (this.filterUserId !== '') myQuery.where(this.userTabs.paypal.active ? 'PaymentTransactionUserId' : 'PaymentTransactionUserId', this.filterUserId)

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },

      checkCountFilter(withoutParams = false) {
        this.countFilterParams = this.checkCountFilterFromUrl(this, withoutParams)
      },

      downloadFiles(fileType) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        if(this.userTabs.payoneer.active) {

          this.$store.dispatch('getExportMyPaymentsPayoneer', {filter: url, exportType: fileType}).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              const blob = new Blob([response.data], {type: `application/${fileType}`})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `My payments payoneer.${fileType}`
              link.click()
              URL.revokeObjectURL(link.href)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

        } else {
          let type = this.filterType !== '' ? this.filterType : 'paypal'

          this.$store.dispatch('getExportMyPayments', {filter: url, type: type, exportType: fileType}).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              const blob = new Blob([response.data], {type: `application/${fileType}`})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `My payments.${fileType}`
              link.click()
              URL.revokeObjectURL(link.href)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })
        }



      },

      printInvoice(data) {
        let exportIds = this.getCheckedRows('row-name')

        if(Object.hasOwnProperty.call(data, 'selected')) {
          exportIds = this.getCheckedRows('row-name').join(',')
        } else {
          exportIds = data.id
        }

        if (exportIds.length === 0) return

        if(this.userTabs.authorize.active) {
          let routeData =
              this.$router.resolve({
            path: this.$store.getters.GET_PATHS.generatePdfFile + '/my-payments-authorize-print',
            query: {
              paymentSystem: this.userTabs.authorize.value,
              paymentTransactionIds: exportIds,
              date: data.date
            }
          })

          window.open(routeData.href, '_blank');
          return
        }

        let postData = {
          invoiceIds: exportIds,
          date: data.date
        }

        this.printData = postData
        this.printInvoicePopup = true
      }


    },

  }
</script>

<style scoped>

</style>
