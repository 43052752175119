<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size invoices-print"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'invoices_printData',
                        ])"></div>
        {{ $t('invoices_printData.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content">

        <div class="profiles-package__alert mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'invoices_PleaseAillDetail',
                        ])"></div>
          {{ $t('invoices_PleaseAillDetail.localization_value.value') }}
        </div>

        <div class="profiles-package__row custom-row">
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'invoices_printFullName',
                        ])"></div>
            <DefaultInput
                :label="$t('invoices_printFullName.localization_value.value')"
                :type="'text'"
                :autocomplete="'name'"
                v-bind:class="{'ui-no-valid': PersonalInformation.validation.personalName}"
                :errorTxt="$t(`${PersonalInformation.validationTranslate.personalName}.localization_value.value`)"
                :error="PersonalInformation.validation.personalName"
                v-model="PersonalInformation.information.personalName"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'invoices_printCompanyName',
                        ])"></div>
            <DefaultInput
                :label="$t('invoices_printCompanyName.localization_value.value')"
                :type="'text'"
                :autocomplete="'organization'"
                v-model="PersonalInformation.information.personalCompany"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_email',
                        ])"></div>
            <DefaultInput
                :label="$t('common_email.localization_value.value')"
                :type="'text'"
                :autocomplete="'email'"
                v-bind:class="{'ui-no-valid': PersonalInformation.validation.personalEmail}"
                :errorTxt="$t(`${PersonalInformation.validationTranslate.personalEmail}.localization_value.value`)"
                :error="PersonalInformation.validation.personalEmail"
                v-model="PersonalInformation.information.personalEmail"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_address',
                        ])"></div>
            <DefaultInput
                :label="$t('common_address.localization_value.value')"
                :type="'text'"
                :autocomplete="'address-line1'"
                v-bind:class="{'ui-no-valid': Delivery.validation.address}"
                :errorTxt="$t(`${Delivery.validationTranslate.address}.localization_value.value`)"
                :error="Delivery.validation.address"
                v-model="Delivery.delivery.address"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_city',
                        ])"></div>
            <DefaultInput
                :label="$t('common_city.localization_value.value')"
                :type="'text'"
                :autocomplete="'address-level2'"
                v-bind:class="{'ui-no-valid': Delivery.validation.city}"
                :errorTxt="$t(`${Delivery.validationTranslate.city}.localization_value.value`)"
                :error="Delivery.validation.city"
                v-model="Delivery.delivery.city"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_region',
                        ])"></div>
            <DefaultInput
                :label="$t('common_region.localization_value.value')"
                :type="'text'"
                :autocomplete="'address-level1'"
                v-bind:class="{'ui-no-valid': Delivery.validation.region}"
                :errorTxt="$t(`${Delivery.validationTranslate.region}.localization_value.value`)"
                :error="Delivery.validation.region"
                v-model="Delivery.delivery.region"
            />
          </div>
          <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_country' ])"></div>
            <DefaultSelect
                :label="$t('common_country.localization_value.value')"
                :options="options"
                :optionsLabel="'label'"
                v-bind:class="{'ui-no-valid': Delivery.validation.country}"
                :errorTxt="$t(`${Delivery.validationTranslate.country}.localization_value.value`)"
                :error="Delivery.validation.country"
                :selected="Delivery.delivery.country"
                @change="changeCountry"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_zipCode',
                        ])"></div>
            <DefaultInput
                :label="$t('common_zipCode.localization_value.value')"
                :type="'text'"
                :autocomplete="'postal-code'"
                v-bind:class="{'ui-no-valid': Delivery.validation.zip}"
                :errorTxt="$t(`${Delivery.validationTranslate.zip}.localization_value.value`)"
                :error="Delivery.validation.zip"
                v-model="Delivery.delivery.zip"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_vatNumber',
                        ])"></div>
            <DefaultInput
                :label="$t('common_vatNumber.localization_value.value')"
                :type="'text'"
                v-model="VatNumber.data.vatNumber"
            />
          </div>
        </div>


      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center">

          <span
              class="site-link site-link--alt buy-label__btn-i mr-3"
              @click="$emit('close')"
          >
            {{ $t('common_cancel.localization_value.value') }}
          </span>

        <MainButton
            :value="'Print Invoice'"
            v-bind:class="{'disabled-btn' : $store.getters.getProfilesPackagingBtn}"
            class="profiles-package__btn-i"
            @click.native="printInvoice"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {PersonalInformation} from "@/components/globalModels/PersonalInformation";
import {Delivery} from "@/components/globalModels/Delivery";
import {VatNumber} from "@/components/globalModels/VatNumber";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import {filterCountriesUSA} from "@/services/filterCountries";

export default {
  name: "PrintPaymentInvoicePopup",
  components: {DefaultSelect, MainButton, DefaultInput, Modal},

  props: {
    printData: {
      type: Object,
    },
    userTabs: {
      type: Object,
    },
  },

  data() {
    return {
      PersonalInformation: new PersonalInformation(),
      Delivery: new Delivery(),
      VatNumber: new VatNumber(),

      options: [],
    }
  },

  mounted() {

    let defaultData = JSON.parse(localStorage.getItem('davePrintPaymentInvoice' ))

    console.log(defaultData);

    if(defaultData) {
      this.PersonalInformation.information.personalName = defaultData.fullName
      this.PersonalInformation.information.personalCompany = defaultData.companyName
      this.PersonalInformation.information.personalEmail = defaultData.email
      this.Delivery.delivery.address = defaultData.address
      this.Delivery.delivery.city = defaultData.city
      this.Delivery.delivery.region = defaultData.region
      this.Delivery.delivery.country = defaultData.country
      this.Delivery.delivery.zip = defaultData.zip
      this.VatNumber.data.vatNumber = defaultData.vatNumber
    }

    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      filterCountriesUSA(countries)
      this.options = countries;
    })
  },

  methods: {
    printInvoice() {

      if (!this.PersonalInformation.personalInfoPartsValidate(
          {
            personalName: true,
            personalPhone: false,
            personalCompany: false,
            personalEmail: true,
            phoneEmpty: false,
            phoneNumber: false,
            phoneOptional: false,
            emailOptional: false,
            nameNoLatin: false,
          }
      )) {
        return
      }

      if (!this.Delivery.deliveryInfoPartialValidate(
          {
            address: true,
            address2: false,
            city: true,
            region: true,
            country: true,
            zip: true,
            latin: false,
          }
      )) {
        return
      }

      let data = {
        ids: this.printData.invoiceIds,
        date: this.printData.date,
        fullName: this.PersonalInformation.information.personalName,
        companyName: this.PersonalInformation.information.personalCompany,
        email: this.PersonalInformation.information.personalEmail,
        address: this.Delivery.delivery.address,
        city: this.Delivery.delivery.city,
        region: this.Delivery.delivery.region,
        country: this.Delivery.delivery.country,
        countryCode: this.Delivery.delivery.country?.code_iso_2,
        zip: this.Delivery.delivery.zip,
        vatNumber: this.VatNumber.data.vatNumber,
        type: this._.find(this.userTabs, {active: true})?.value
      }

      localStorage.setItem('davePrintPaymentInvoice', JSON.stringify(data))

      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.generatePdfFile + '/paymentInvoicePrint',
        query: data
      })
      window.open(routeData.href, '_blank');
      this.$emit('close')
    },

    changeCountry(val) {
      this.Delivery.setCountry(val)
    },
  },

}
</script>

<style scoped>

</style>